@use "../../foundation/import" as *

/* -------------------------------
 *  parts styles
 * ---------------------------- */
.c-mobile-bottom-navigation
  display: none
  position: fixed
  width: 100%
  bottom: 0
  left: 0
  z-index: var(--layer-order__fixed-content-default)
  background: var(--base-color__7)
  height: 110px
  +media-query(md)  
    display: flex
    flex-direction: column
  .__list
    &.__list--top
      .__item
        margin: var(--space-XS) var(--space-XS) 0 var(--space-XS)
        .__link
          display: flex
          justify-content: center
          align-items: center
          width: 40px
          height: 40px
          border-radius: 100%
          background: var(--color-white)
          .__image
            width: 60%
    &.__list--bottom
      padding: var(--space-XS)
      .__item
        width: calc(50% - var(--space-XXXS))
        .__button
          display: block
          text-align: center
          padding: var(--space-XXXS)
          text-decoration: none
          color: var(--color-white)
          font-weight: bold
          width: 100%
          &.__button--tel
            background: var(--primary-color__4)
          &.__button--email
            background: var(--accent-color__4)

.c-scroll-top 
  position: fixed
  bottom: 130px
  right: 20px            
  width: 50px
  height: 50px
  font-size: 0
  background: var(--primary-color__4)
  z-index: var(--layer-order__fixed-content-default)
  box-shadow: var(--hover-box-shadow)
  display: flex
  justify-content: center
  align-items: center
  transform: translate(80px, 0)
  transition: transition(transform)
  &.c-scroll-top--shown
    transform: translate(0, 0)
  .__icon
    width: 30px
    height: 30px
    background: var(--color-white)
    clip-path: polygon(50% 0, 100% 50%, 100% 100%, 50% 50%, 0 100%, 0 48%)

.c-anchor-point
  margin-top: 0
  padding-top: 0