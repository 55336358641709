@use "../../foundation/import" as *

/* -------------------------------
 *  navigation elements
 * ---------------------------- */
.c-navigation-header  
  .__list
    .__item
      &:not(:first-child)
        margin: 0 0 0 var(--space-S)

.c-navigation-footer-sitemap
  .__list
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    .__item
      line-height: 1
      margin: var(--space-S) 0 0 0
      &:not(:last-child)
        margin: var(--space-S) var(--space-S) 0 0
        padding: 0 var(--space-S) 0 0
        border-right: 1px solid var(--primary-color__5)
      .__link
        text-decoration: none
        color: var(--color-white)
        font-weight: bold
