@use "../foundation/import" as *

/* -------------------------------
 *  header
 * ---------------------------- */
.l-header
  grid-area: header
  position: fixed
  z-index: var(--layer-order__fixed-content-default)
  top: 0
  left: 0
  width: 100%
  background: var(--color-white)
  box-shadow: var(--default-box-shadow)
  transition: transition(transform)
  transform: translate(0, -80px)
  &.l-header--shown
    transform: translate(0, 0)
  .__block-depth-1
    height: 80px
    .__logo
      .__image
        +media-query(sm)
          &:nth-child(1)
            width: 50px
          &:nth-child(2)
            width: calc(100% - 50px)