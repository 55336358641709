@use "../../foundation/import" as *

/* -------------------------------
 *  list components
 * ---------------------------- */
.u-list-contact
  padding: 0 0 var(--space-S) 0
  border-bottom: 1px solid var(--primary-color__4)
  .__item
    display: flex
    justify-content: flex-start
    align-items: center
    &:not(:first-child)
      margin: var(--space-S) 0 0 0
    .__icon
      flex-shrink: 0
      width: 40px
      height: 40px
      display: flex
      justify-content: center
      align-items: center
      border-radius: 100%
      border: 1px solid var(--color-white)
    .__typo
      margin: 0 0 0 var(--space-XS)
      .__text
        color: var(--color-white)
        font-weight: bold
        font-family: var(--font-serif)
        &.__text--tel
          font-size: var(--font-XXL)
        &.__text--email
          font-size: var(--font-S)
.u-list-sns
  display: flex
  justify-content: flex-end
  align-items: center
  +media-query(md)
    justify-content: center
  .__item
    margin: var(--space-S) 0 0 var(--space-S)
    +media-query(md)
      margin: var(--space-S) var(--space-XXS) 0 var(--space-XXS)

.u-list-philosophy
  display: inline-block
  background: var(--color-white) 
  box-shadow: var(--default-box-shadow)     
  padding: var(--space-R) var(--space-XL)
  transform: translate(0, var(--space-XL))
  +media-query(md)
    padding: var(--space-R)
    max-width: 80%
  .__item 
    font-family: var(--font-serif)
    color: var(--secondary-color__5)
    font-size: var(--font-XL)
    &:not(:first-child)
      margin: var(--space-S) 0 0 0
      padding: var(--space-S) 0 0 0
      position: relative
      &::before
        content: ""
        width: 80px
        height: 1px 
        background: var(--secondary-color__3)
        position: absolute
        top: 0
        left: calc(50% - 40px)

.u-list-anchor-service-content
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  margin: var(--space-R) 0 0 0
  .__item
    margin: var(--space-R) 0 0 0
    width: calc(25% - 18px)
    +media-query(lg)
      width: calc(50% - 12px)
    +media-query(sm)
      width: 100%
    .__button
      border: 2px solid var(--primary-color__3)
      display: block
      padding: var(--space-XS)
      text-align: center
      transition: transition(background) 
      +hover-style()
        background: rgba(255, 255, 255, 0.2)
      .__text
        display: inline-block
        text-decoration: none
        color: var(--color-white)
        font-weight: bold
        padding: 0 0 var(--space-R) 0
        position: relative
        &::before,
        &::after
          content: ""
          width: 16px
          height: 2px
          background: var(--primary-color__3)
          position: absolute
          bottom: 0
          left: calc(50% - 8px)
          border-radius: 2px
        &::before
          transform: rotate(45deg) translate(-50%, 0)
        &::after
          transform: rotate(-45deg) translate(50%, 0)

.u-list-external-link
  margin: var(--space-S) 0 0 0
  .__item
    position: relative
    padding: 0 0 0 1.2em
    &::before
      content: "・"
      position: absolute
      left: 0
      top: 0
      color: var(--base-color__4)
    &:not(:first-child)          
      margin: var(--space-S) 0 0 0

.u-list-profile
  .__item
    display: flex
    justify-content: space-between
    align-items: flex-end
    .__icon
      width: 200px
      height: 200px
      flex-shrink: 0
      +media-query(sm)
        width: 160px
        height: 160px
    .__information
      flex-shrink: 1
      flex-grow: 1
      margin: 0 0 0 var(--space-R)
      .__name
        .__ja
          font-size: var(--font-XXL)
          font-weight: bold
          color: var(--color-white)
        .__en
          display: block
          margin: var(--space-XXXS) 0 0 0
          padding: var(--space-XXS) 0 0 0
          border-top: 1px solid var(--primary-color__4)
          text-align: right
          font-family: var(--font-playfair)
          font-style: italic
          color: var(--primary-color__3)