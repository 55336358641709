@use "../../foundation/import" as *

/* -------------------------------
 *  button components
 * ---------------------------- */
.u-button-contact-label
  display: flex
  justify-content: space-between
  align-items: center
  text-decoration: none
  padding: var(--space-XXXS)
  border-radius: 99em
  &.u-button-contact-label--tel
    background: var(--primary-color__5)
  &.u-button-contact-label--email
    background: var(--accent-color__4)
  .__icon
    width: 40px
    height: 40px
    display: flex
    justify-content: center
    align-items: center
    background: var(--color-white)
    border-radius: 100%
  .__typo
    width: 200px
    text-align: center
    +media-query(lg)
      display: none
    .__text
      color: var(--color-white)
      font-weight: bold
      &.__text--en
        font-size: var(--font-XXL)
        font-family: var(--font-serif)
        line-height: 1.2
    
.u-button-default
  display: inline-block
  min-width: 200px
  border: 2px solid var(--accent-color__4)
  text-align: center
  padding: var(--space-S)
  text-decoration: none
  font-weight: bold
  color: var(--accent-color__4)
  .__text
    font-weight: bold
    color: var(--accent-color__4)