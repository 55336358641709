@use "../../foundation/import" as *

/* -------------------------------
 *  form components
 * ---------------------------- */
.u-form-contact  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  textarea
    width: 100%
    padding: var(--space-XXS)
    border-radius: var(--default-border-radius)
    border: 1px solid var(--base-color__3)
    background: var(--color-white)
  textarea
    min-height: 160px

.u-form-select-wrap
  position: relative
  & > *
    position: relative
    &::before,
    &::after
      content: ""
      width: 12px
      height: 2px
      background: var(--base-color__4)
      position: absolute
      right: var(--space-S)
      top: calc(50% + 4px)
      border-radius: 2px
    &::before
      transform: rotate(45deg) translate(-50%, 0)
    &::after
      transform: rotate(-45deg) translate(50%, 0)
    select
      width: 100%
      padding: var(--space-XXS)
      border-radius: var(--default-border-radius)
      border: 1px solid var(--base-color__3)
      background: var(--color-white)
    