@use "../../foundation/import" as *

/* -------------------------------
 *  heading components
 * ---------------------------- */
.u-heading-content-title
  padding: 0 0 var(--space-S) 0
  position: relative
  &::after
    content: ""
    width: 80px
    height: 1px 
    position: absolute
    left: calc(50% - 40px)
    bottom: 0
  .__en,
  .__ja
    display: block
    text-align: center
  .__en
    font-family: var(--font-serif)
    font-size: 8.0rem
    font-weight: bold
    line-height: 1
  .__ja
    +typography-catchcopy()
    line-height: 1
    transform: translate(0, -50%)
  &.u-heading-content-title--green
    &::after
      background: var(--primary-color__3)
    .__en
      color: var(--primary-color__5)
    .__ja
      color: var(--color-white)
  &.u-heading-content-title--gold
    &::after
      background: var(--secondary-color__4)
    .__en
      color: var(--secondary-color__3)
    .__ja
      color: var(--secondary-color__6)

.u-heading-service-content
  padding: 0 0 var(--space-XS) 0
  position: relative
  +typography-headline()
  font-family: var(--font-serif)
  &::before,
  &::after
    content: ""
    height: 2px
    position: absolute
    left: 0
    bottom: 0
  &::before
    width: 100%
    background: var(--base-color__2)
  &::after
    width: 33.3333%
    background: var(--primary-color__4)
          
.u-heading-content-headline
  display: flex
  justify-content: space-between
  align-items: center
  .__text 
    +typography-headline()
    color: var(--primary-color__5)
    flex-shrink: 0
    margin: 0 var(--space-S) 0 0 
    +media-query(sm)
      +typography-headline($device: mobile)
  .__line
    height: 2px 
    background: var(--base-color__2)
    flex-grow: 1
    flex-shrink: 1

.u-heading-content-title-sub
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  align-items: baseline
  line-height: 1.4
  .__en
    font-size: 6.0rem
    font-weight: bold
    font-family: var(--font-serif)
    color: var(--primary-color__1)
    +media-query(sm)
      font-size: 4.2rem
  .__ja
    font-family: var(--font-serif)
    color: var(--color-white)
    +typography-headline()
    margin: 0 0 0 var(--space-XS)
    +media-query(sm)
      +typography-headline($device: mobile)