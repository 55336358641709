@use "../../foundation/import" as *

/* -------------------------------
 *  text components
 * ---------------------------- */
.u-link-external
  color: var(--accent-color__4)
  font-weight: bold
  padding: 0 1.6em 0 0
  position: relative 
  display: inline-block
  &::after
    content: url(../img/site/icon_link_external_min.svg)
    position: absolute
    right: 0
    top: calc(50% - 9px)