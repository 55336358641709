/* -------------------------------
 *  default style
 * ---------------------------- */
$default-color: #333 !default
$default-font-size: 1.6rem !default
$default-font-family: "Helvetica Neue", "Helvetica", 'Noto Sans JP', sans-serif !default
$default-line-height: 1.75 !default
$default-letter-spacing: 0.1rem !default
$default-border-radius: 8px !default
$default-transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s !default
$default-text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5) !default
$default-box-shadow: 0 0 16px rgba(0, 0, 0, 0.15) !default
$default-box-shadow-inner: 0 0 8px rgba(0, 0, 0, 0.25) inset !default

/* -------------------------------
 *  hover style
 * ---------------------------- */
$hover-color: #999 !default
$hover-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25) !default

/* -------------------------------
 *  screen size & breakpoint
 * ---------------------------- */
$screen-xxl: 1440 !default
$screen-xl: 1280 !default
$screen-lg: 992 !default
$screen-md: 768 !default
$screen-sm: 544 !default
$screen-xs: 480 !default
$screen-xxs: 360 !default
$breakpoint-list: (switch: #{$screen-md}, xxs: #{$screen-xxs}, xs: #{$screen-xs}, sm: #{$screen-sm}, md: #{$screen-md}, lg: #{$screen-lg}, xl: #{$screen-xl}, xxl: #{$screen-xxl}) !default

/* -------------------------------
 *  font family
 * ---------------------------- */
$font-sans-serif: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", Arial, "Yu Gothic Medium", "Yu Gothic", YuGothic, Meiryo, sans-serif !default
// $font-serif: "Times New Roman", YuMincho, "Yu Mincho", "Hiragino Mincho ProN", "MS PMincho", serif !default
$font-serif: 'Noto Serif JP', serif !default
$font-playfair: 'Playfair Display', serif !default
$font-monospace: SFMono, Consolas, "Roboto Mono", "Courier New", Courier, Meiryo, monospace !default

/* -------------------------------
 *  space size
 * ---------------------------- */
$space-XXXXL: 160px !default
$space-XXXL: 128px !default
$space-XXL: 96px !default
$space-XL: 64px !default
$space-L: 48px !default
$space-M: 32px !default
$space-R: 24px !default
$space-S: 16px !default
$space-XS: 12px !default
$space-XXS: 8px !default
$space-XXXS: 4px !default

/* -------------------------------
 *  font size
 * ---------------------------- */
$font-XXXXL: 4rem !default
$font-XXXL: 3.2rem !default
$font-XXL: 2.8rem !default
$font-XL: 2.4rem !default
$font-L: 2rem !default
$font-M: 1.8rem !default
$font-R: 1.6rem !default
$font-S: 1.4rem !default
$font-XS: 1.2rem !default
$font-XXS: 1rem !default
$font-XXXS: 0.8rem !default

/* -------------------------------
 *  line spacing
 * ---------------------------- */
$line-height-caption: 1.75 !default
$line-height-bodycopy: 1.75 !default
$line-height-leadcopy: 1.84 !default
$line-height-headline: 1.6 !default
$line-height-subcatch: 1.6 !default
$line-height-catchcopy: 1.5 !default
$line-height-title: 1.4 !default

/* -------------------------------
 *  kerning
 * ---------------------------- */
$letter-spacing-caption: 0 !default
$letter-spacing-bodycopy: 0 !default
$letter-spacing-leadcopy: 0 !default
$letter-spacing-headline: 0.05rem !default
$letter-spacing-subcatch: 0.05rem !default
$letter-spacing-catchcopy: 0.1rem !default
$letter-spacing-title: 0.2rem !default

/* -------------------------------
 *  layer order
 * ---------------------------- */
$layer-order-list: (fixed-content-default: 100, fixed-content-alert: 200, drawer-navi: 300, dialog: 400, loading-screen: 500) !default
@function layer-order($layer-order-key)
  @return #{map-get($layer-order-list, $layer-order-key)}

/* -------------------------------
 *  directory path
 * ---------------------------- */
$directory-path-list: (document-root: "/", images-directory: "assets/img/") !default
@function directory-path($directory-path-key)
  @return #{map-get($directory-path-list, $directory-path-key)}