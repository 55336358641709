@use "../../foundation/import" as *

/* -------------------------------
 *  box components
 * ---------------------------- */
.u-box-blog-archive  
  display: flex
  justify-content: space-between
  flex-wrap: wrap
  .__thumbnail,
  .__blog-meta
    width: 50%
    +media-query(md)
      width: 100%
  .__thumbnail
    overflow: hidden
    aspect-ratio: 3 / 2
    .__image
      width: 100%
      height: 100%
      object-fit: cover
  .__blog-meta
    padding: var(--space-S)
    display: flex
    flex-direction: column
    justify-content: space-between
    .__blog-date
      color: var(--base-color__4)
      font-size: var(--font-S)

.u-box-map-wrapper
  aspect-ratio: 4 / 1
  +media-query(lg)
    aspect-ratio: 3 / 1
  +media-query(md)
    aspect-ratio: 2 / 1
  +media-query(sm)
    aspect-ratio: 1 / 1
  iframe
    width: 100%
    height: 100%