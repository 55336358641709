@use "../../foundation/import" as *

/* -------------------------------
 *  common
 * ---------------------------- */

/* -------------------------------
 *  01_home
 * ---------------------------- */
.p-front-page
  overflow: hidden
  .p-main-visual
    position: relative
    min-height: 700px
    +media-query(md)
      min-height: 500px
    +media-query(sm)
      min-height: 320px
    .p-background-section
      position: absolute
      left: 0
      top: 0
      z-index: 1
      width: 100%
      height: 100%
      & > *
        height: 100%
      .__background
        height: 100%
        width: 91.6666%
        margin: 0 0 0 8.3333%
        position: relative
        +media-query(md)
          margin: 0 0 0 16.6666%
        .__image-wrap
          display: block
          position: absolute
          left: 0
          top: 0
          height: 100%
          width: 100vw
          .__image
            width: 100%
            height: 100%
            object-fit: cover
            object-position: 70% center
    .p-foreground-section
      position: relative
      z-index: 3
      width: 100%
      min-height: 700px
      height: 100%
      display: flex
      flex-direction: column
      justify-content: space-between
      +media-query(md)
        min-height: 500px
      +media-query(sm)
        min-height: 400px
      .__contents
        &.__contents--top
          .__block-depth-1
            height: 80px
            .__block-depth-2
              &.__block-depth-2--1st
                margin: 0 var(--space-R) 0 0
                .__badge
                  .__typo
                    font-family: var(--font-serif)
                    margin: 0 0 0 var(--space-XS)
                    line-height: 1.2
        &.__contents--middle
          .__catchcopy
            max-width: 18em
            +typography-title()
            +media-query(md)
              +typography-title($device: mobile)
            +media-query(sm)
              +typography-headline()
            & > span 
              +media-query(sm)
                background: linear-gradient(0deg, #fff 0%, #fff 80%, transparent 80%, transparent 100%)
          .__catchcopy-sub
            margin: var(--space-XXS) 0 0 0
            +typography-leadcopy()
            max-width: 26em
            +media-query(md)
              +typography-leadcopy($device: mobile)
            +media-query(sm)
              +typography-bodycopy()
              font-weight: bold
            & > span 
              +media-query(sm)
                background: linear-gradient(0deg, #fff 0%, #fff 80%, transparent 80%, transparent 100%)
        &.__contents--bottom
          transform: translate(0, 50%)
          .__title-area
            display: inline-flex
            justify-content: flex-start
            align-items: center
            position: relative
            &::before
              content: ""
              width: 100vw
              height: calc(100% - 32px)
              position: absolute
              right: 0
              top: 16px
              background: var(--gradation-theme-light)
              +media-query(md)
                height: calc(100% - 16px)
                top: 8px
              +media-query(sm)
                height: 100%
                top: 0
            .__logo,
            .__text
              position: relative
              z-index: 2
            .__text
              margin: 0 0 0 var(--space-S)
              padding: 0 var(--space-S) 0 0
              +media-query(sm)
                padding: var(--space-XXS) var(--space-S) var(--space-XXS) 0
              .__lead
                font-size: var(--font-S)
                color: var(--color-white)
                +media-query(sm)
                  font-size: var(--font-XXS)
              .__typo
                margin: var(--space-XS) 0 0 0
                +media-query(sm)
                  margin: var(--space-XXXS) 0 0 0
  .p-section-01
    background: var(--secondary-color__1)
    position: relative
    padding: var(--space-XXXXL) 0 0 0
    +media-query(sm)
      padding: var(--space-XXL) 0 0 0
    .p-background-section
      position: absolute
      z-index: 1
      left: 0
      bottom: 0
      width: 100%
      .__background
        display: block
        text-align: center
    .p-foreground-section
      position: relative
      z-index: 2
      .__block-depth-2
        &.__block-depth-2--1st
          .__title
            text-align: right
            .__logo
              display: inline-block
              position: relative
              +media-query(sm)
                max-width: 80%
              &::after
                content: "" 
                width: 100vw
                height: 2px 
                background: var(--secondary-color__3)
                position: absolute
                left: 0
                bottom: 22px
                +media-query(md)
                  bottom: 3vw
              .__image
                position: relative
                z-index: 1
        &.__block-depth-2--2nd
          margin: var(--space-XL) 0 0 0
          .__headline
            +typography-subcatch()
            color: var(--secondary-color__5)
            font-family: var(--font-serif)
          .__text
            margin: var(--space-R) 0 0 0
            +typography-leadcopy()
            +media-query(sm)
              +typography-leadcopy($device: mobile)
        &.__block-depth-2--3rd
          text-align: center
  .p-section-02
    .p-navigation-section
      background: var(--primary-color__6)
      padding: var(--space-XXXL) 0 var(--space-XL) 0
    .__title
      & + .__text
        max-width: 576px
        margin: var(--space-L) auto 0 auto
        color: var(--color-white)
    .p-service-section
      margin: var(--space-XXL) 0 0 0
      padding: 0 0 var(--space-XL) 0
      position: relative
      &::before
        content: ""
        width: 100%
        height: calc(100% - 216px)
        position: absolute
        left: 0
        top: 216px
      .__block-depth-2
        position: relative
        z-index: 1
        &.__block-depth-2--1st
          .__block-depth-3
            display: flex
            align-items: center
            justify-content: space-between
            flex-wrap: nowrap
            +media-query(md)
              flex-wrap: wrap
            .__block-depth-4
              &.__block-depth-4--1st
                width: 58.3333%
                min-height: 432px
                position: relative
                +media-query(md)
                  width: 100%
                .__visual
                  position: absolute
                  top: 0
                  width: 60vw
                  height: 432px
                  +media-query(md)
                    width: calc(100% * var(--space-R))
                    margin: 0 calc(var(--space-XS) * -1)
                  .__image
                    object-fit: cover
                    width: 100%
                    height: 100%
              &.__block-depth-4--2nd
                width: 41.6666%
                +media-query(md)
                  width: 100%
                .__box
                  padding: var(--space-R)
                  box-shadow: var(--default-box-shadow)
                  .__text
                    margin: var(--space-R) 0 0 0
        &.__block-depth-2--2nd
          margin: var(--space-L) 0 0 0
          +media-query(md)
            margin: 0
          .__block-depth-3
            .__lead
              font-size: var(--font-L)
              color: var(--primary-color__6)
              margin: 0 0 var(--space-R) 0
            .__block-depth-4
              width: 100%
              max-width: calc(50% - var(--space-R))
              margin: 0 var(--space-XS)
              +media-query(md)
                max-width: 100%
              &.__block-depth-4--width-single-column
                max-width: 616px
              &:not(:first-child)
                table
                  tr
                    &:first-child
                      th
                        +media-query(md)
                          border-top: none
      &.p-service-section-gray
        &::before
          background: var(--base-color__1)
        .__block-depth-2
          &.__block-depth-2--1st
            .__block-depth-3
              .__block-depth-4
                &.__block-depth-4--1st
                  order: 2
                  +media-query(md)
                    order: 1
                  .__visual
                    left: 0
                &.__block-depth-4--2nd
                  order: 1
                  transform: translate(calc(20% - 24px), 0)
                  +media-query(md)
                    order: 2
                    transform: translate(0, calc(var(--space-L) * -1))
                  .__box
                    background: var(--color-white)
      &.p-service-section-green
        &::before
          background: var(--primary-color__1)
        .__block-depth-2
          &.__block-depth-2--1st
            .__block-depth-3
              .__block-depth-4
                &.__block-depth-4--1st
                  order: 1
                  .__visual
                    right: 0
                &.__block-depth-4--2nd
                  order: 2
                  transform: translate(calc(-20% + 24px), 0)
                  +media-query(md)
                    transform: translate(0, calc(var(--space-L) * -1))
                  .__box
                    background: var(--base-color__1)
    .__notice
      margin: var(--space-L) 0 0 0
      .__item
        padding: 0 0 0 1.2em
        position: relative
        &::before
          content: "※"
          position: absolute
          left: 0
          top: 0
  .p-section-03
    padding: var(--space-XL) 0
    .__block-depth-2
      &.__block-depth-2--1st
        .__list
          display: flex
          justify-content: space-between
          flex-wrap: wrap
          .__item
            width: calc(50% - 12px)
            margin: var(--space-R) 0 0 0
            border: 1px solid var(--base-color__3)
            position: relative
          .__link
            position: absolute
            width: 100%
            height: 100%
            left: 0
            top: 0
            display: block
      &.__block-depth-2--2nd
        margin: var(--space-L) 0 0 0
  .p-section-04
    background: var(--secondary-color__1)
    padding: var(--space-XL) 0
    .__block-depth-2
      &.__block-depth-2--1st
        width: 50%
        min-width: 616px
        margin: 0 auto
        +media-query(md)
          width: 90%
          min-width: auto
        .__text,
        .__notice
          text-align: center
          font-weight: bold
        .__text
          margin: var(--space-L) 0 0 0
        .__notice
          margin: var(--space-R) 0 0 0
          background: var(--secondary-color__4)
          color: var(--color-white)
          padding: var(--space-R)
      &.__block-depth-2--2nd
        max-width: calc(var(--screen-md) * 1px)
        margin: var(--space-L) auto 0 auto
        .__form
          .__box
            background: var(--color-white)
            box-shadow: var(--default-box-shadow)
            padding: var(--space-R)
            .__list
              & + div
                margin: var(--space-R) 0 0 0
              .__item
                &:not(:first-child)
                  margin: var(--space-R) 0 0 0
                .__term
                  font-weight: bold
                  &.__term--required
                    & > *
                      display: inline-block
                      &::after
                        content: "必須"
                        margin: 0 0 0 1em
                        color: var(--ui-color__error)
                        font-size: var(--font-XS)
                .__body
                  margin: var(--space-XXS) 0 0 0
  .p-section-05
    padding: var(--space-XL) 0
  .p-section-06
    background: var(--gradation-theme-medium)
    padding: var(--space-XL) 0 0 0 
    .__block-depth-2
      width: calc(50% - 12px)
      +media-query(md)
        width: 100%
      margin: 0 0 var(--space-XL) 0
      .__title
        margin: 0 0 var(--space-R) 0
      .__text
        margin: var(--space-R) 0 0 0
        color: var(--color-white)