@use "../foundation/import" as *

/* -------------------------------
 *  footer
 * ---------------------------- */
.l-footer
  grid-area: footer
  +media-query(md)
    margin: 0 0 110px 0
  .l-wrapper
    background: var(--gradation-theme-dark)
    padding: var(--space-M) 0 var(--space-R) 0
    .__block-depth-2
      &.__block-depth-2--1st
        .__block-depth-4
          &.__block-depth-4--1st
            width: calc(66.6666% - var(--space-XS))
            +media-query(md)
              width: 100%
            .__logo
              .__image
                &:nth-child(1)
                  width: calc(120px - var(--space-S))
                &:nth-child(2)
                  max-width: calc(100% - 120px)
                  margin: 0 0 0 var(--space-S)
                  +media-query(md)
                    max-width: 100%
                    margin: var(--space-S) 0 0 0 
            .__badge
              margin: var(--space-S) 0 0 0
              .__typo
                color: var(--color-white)
                font-family: var(--font-serif)
                margin: 0 0 0 var(--space-S)
                +media-query(md)
                  text-align: center
                  margin: var(--space-S) 0 0 0 
            .__logo,
            .__badge
              .__image-group
                display: flex
                justify-content: center
                align-items: center
                +media-query(md)
                  flex-direction: column
          &.__block-depth-4--2nd
            width: calc(33.3333% - var(--space-XS))
            +media-query(md)
              width: 100%
              margin: var(--space-M) 0 0 0
      &.__block-depth-2--2nd
        margin: var(--space-S) 0 0 0
  .__copyright
    background: var(--base-color__7)
    padding: var(--space-XS)
    .__text
      color: var(--color-white)
      font-size: var(--font-XS)
      line-height: 1