@use "../../foundation/import" as *
/* -------------------------------
 *  table components
 * ---------------------------- */
.u-table-default
  width: 100%
  tbody  
    tr
      th,td
        padding: var(--space-XS) var(--space-S)
        border-bottom: 1px solid
        .__small
          font-size: var(--font-S)
        +media-query(md)
          display: block
          width: 100%
          padding: var(--space-XXS) var(--space-S)
      th
        text-align: left
        +media-query(md)
          padding: var(--space-XXS) var(--space-S) 0 var(--space-S)
          border-bottom: none
      td
        +media-query(md)
          padding: 0 var(--space-S) var(--space-XXS) var(--space-S)
      &:first-child
        th,td
          border-top: 1px solid
        td
          +media-query(md)
            border-top: none
  &.u-table-default--service
    tbody
      tr
        th,td
          border-color: var(--base-color__3)
  &.u-table-default--about
    tbody
      tr
        th,td
          border-color: var(--primary-color__4)
          color: var(--color-white)