@use "sass:math" as *
@use "variable" as *

/* -------------------------------
 *  typography
 * ---------------------------- */
=typography-title($device: desktop)
  @if $device == mobile
    font-size: $font-XXXL
    line-height: $line-height-catchcopy
    letter-spacing: $letter-spacing-catchcopy
    font-weight: bold
  @else if $device == desktop
    font-size: $font-XXXXL
    line-height: $line-height-title
    letter-spacing: $letter-spacing-title
    font-weight: bold
=typography-catchcopy($device: desktop)
  @if $device == mobile
    font-size: $font-XXL
    line-height: $line-height-subcatch
    letter-spacing: $letter-spacing-subcatch
    font-weight: bold
  @else if $device == desktop
    font-size: $font-XXXL
    line-height: $line-height-catchcopy
    letter-spacing: $letter-spacing-catchcopy
    font-weight: bold
=typography-subcatch($device: desktop)
  @if $device == mobile
    font-size: $font-XL
    line-height: $line-height-headline
    letter-spacing: $letter-spacing-headline
    font-weight: bold
  @else if $device == desktop
    font-size: $font-XXL
    line-height: $line-height-subcatch
    letter-spacing: $letter-spacing-subcatch
    font-weight: bold
=typography-headline($device: desktop)
  @if $device == mobile
    font-size: $font-L
    line-height: $line-height-leadcopy
    letter-spacing: $letter-spacing-leadcopy
    font-weight: bold
  @else if $device == desktop
    font-size: $font-XL
    line-height: $line-height-headline
    letter-spacing: $letter-spacing-headline
    font-weight: bold
=typography-leadcopy($device: desktop)
  @if $device == mobile
    font-size: $font-M
    line-height: $line-height-leadcopy
    letter-spacing: $letter-spacing-leadcopy
    font-weight: bold
  @else if $device == desktop
    font-size: $font-L
    line-height: $line-height-leadcopy
    letter-spacing: $letter-spacing-leadcopy
    font-weight: bold
=typography-bodycopy($device: desktop)
  @if $device == mobile
    font-size: $font-S
    line-height: $line-height-bodycopy
    letter-spacing: $letter-spacing-bodycopy
    font-weight: normal
  @else if $device == desktop
    font-size: $font-R
    line-height: $line-height-bodycopy
    letter-spacing: $letter-spacing-bodycopy
    font-weight: normal
=typography-caption($device: desktop)
  @if $device == mobile
    font-size: $font-XS
    line-height: $line-height-caption
    letter-spacing: $letter-spacing-caption
    font-weight: normal
  @else if $device == desktop
    font-size: $font-XS
    line-height: $line-height-caption
    letter-spacing: $letter-spacing-caption
    font-weight: normal
@function responsive-font-size($target-font-size, $target-screen-size)
  $view-width-ratio: div($target-screen-size, 100)
  @return div($target-font-size, $view-width-ratio) * 1vw